'use strict'

const _ = require('lodash')
const {stripHashIfExists} = require('../helpers/dataUtils')

const isWixappsAppData = appData => appData && (appData.type === 'wixapps' || appData.type === 'ecommerce')

const getBrokenAppInnerIdDataItems = (dataItems, clientSpecMap) =>
    _.pickBy(dataItems, dataItem =>
        dataItem.type === 'AppPart' && dataItem.appInnerID && !isWixappsAppData(clientSpecMap[dataItem.appInnerID]))

const replaceAppPartWithInvisibleContainer = (comp, data, pageId) => {
    const propertyId = `propItem-${comp.id}`
    const propertyItem = {
        type: 'DefaultProperties',
        metaData: {
            pageId
        },
        isHidden: true,
        id: propertyId
    }

    data.component_properties[propertyId] = propertyItem
    comp.propertyQuery = `#${propertyId}`
    const oldDataQuery = comp.dataQuery
    delete data.document_data[oldDataQuery]
    comp.dataQuery = undefined
    comp.componentType = 'mobile.core.components.Container'
}

const searchAndReplaceAppPartIfNeeded = (components, data, brokenAppInnerIdDataItems, pageId) => {
    _.forEach(components, comp => {
        if (comp.dataQuery && brokenAppInnerIdDataItems[stripHashIfExists(comp.dataQuery)]) {
            replaceAppPartWithInvisibleContainer(comp, data, pageId)
        }

        searchAndReplaceAppPartIfNeeded(comp.components, data, brokenAppInnerIdDataItems, pageId)
    })
}

const exec = (pageJson, pageIdsArray, {clientSpecMap}) => {
    const structureData = pageJson.structure
    if (!structureData || !pageJson.data || !clientSpecMap) {
        return
    }

    const pageId = structureData.id
    const brokenAppInnerIdDataItems = getBrokenAppInnerIdDataItems(pageJson.data.document_data, clientSpecMap)
    if (_.isEmpty(brokenAppInnerIdDataItems)) {
        return
    }

    if (structureData.components) {
        searchAndReplaceAppPartIfNeeded(structureData.components, pageJson.data, brokenAppInnerIdDataItems, pageId)
    }
    if (structureData.mobileComponents) {
        searchAndReplaceAppPartIfNeeded(structureData.mobileComponents, pageJson.data, brokenAppInnerIdDataItems, pageId)
    }
    if (structureData.children) {
        searchAndReplaceAppPartIfNeeded(structureData.children, pageJson.data, brokenAppInnerIdDataItems, pageId)
    }
}

module.exports = {
    name: 'appPartBrokenAppInnerIdFixer',
    version: 1,
    exec
}
