'use strict'

const _ = require('lodash')

/**
* @exports utils/dataFixer/plugins/tinyMenuDataFixer
* @type {{exec: function}}
*/
module.exports = {
    name: 'tinyMenuLoginSocialBarRefFixer',
    version: 1,
    exec(pageJson) {
        if (pageJson.structure.type !== 'Document') {
            return
        }

        if (_.get(pageJson.data.document_data.TINY_MENU, 'loginSocialBarRef')) {
            return
        }

        const translationWithLoginSocialBarRef = _.find(pageJson.translations, translation => _.get(translation, 'data.document_data.TINY_MENU.loginSocialBarRef'))

        if (!translationWithLoginSocialBarRef) {
            return
        }

        const {loginSocialBarRef} = translationWithLoginSocialBarRef.data.document_data.TINY_MENU
        _.set(pageJson.data.document_data.TINY_MENU, 'loginSocialBarRef', loginSocialBarRef)
    }
}
