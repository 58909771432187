'use strict'

const _ = require('lodash')

const defaultA11ySettings = {
    visualFocusDisabled: false,
    skipToContent: true
}

const exec = (pageJson, pageIdsArray, magicObject) => {
    if (magicObject.isExperimentOpen('dm_enableDefaultA11ySettings') && _.get(pageJson, 'structure.type') === 'Document') {
        const {accessibilitySettings = {}} = pageJson.data.document_data.masterPage
        _.forOwn(defaultA11ySettings, (val, key) => {
            if (_.isUndefined(accessibilitySettings[key])) {
                accessibilitySettings[key] = val
            }
        })
        pageJson.data.document_data.masterPage.accessibilitySettings = accessibilitySettings //in case there were no settings on the document to begin with
    }
}

module.exports = {
    name: 'a11yDefaultSettings',
    version: 1,
    exec
}
