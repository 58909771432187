'use strict'
const _ = require('lodash')
const {stripHashIfExists} = require('../helpers/dataUtils')

const fixRelationsBreakpointRef = (pageJson, dataType, breakPointRangeNodesToFix) => {
    const mapName = `${dataType}_data`
    const dataMapToFix = pageJson.data[mapName]
    const breakpointRelationNodes = _.pickBy(dataMapToFix, {type: 'BreakpointRelation'})

    _.forEach(breakpointRelationNodes, val => {
        const breakpointId = val.breakpoint && _.replace(val.breakpoint, '#', '')
        const newBreakpointId = breakPointRangeNodesToFix[breakpointId] && `#${breakPointRangeNodesToFix[breakpointId]}`
        if (newBreakpointId) {
            val.breakpoint = newBreakpointId
        }
    })
}

const fixImageXBreakpointRef = (pageJson, breakPointRangeNodesToFix) => {
    const mapName = 'document_data'
    const dataMapToFix = pageJson.data[mapName]
    const imageXDataNodes = _.pickBy(dataMapToFix, {type: 'ImageX'})
    _.forEach(imageXDataNodes, val => {
        const scopedDataWithBreakpointRef = _.pickBy(val.scopedData, 'breakpoint')
        _.forEach(scopedDataWithBreakpointRef, data => {
            const breakpointId = data.breakpoint && _.replace(data.breakpoint, '#', '')
            const newBreakpointId = breakPointRangeNodesToFix[breakpointId] && `#${breakPointRangeNodesToFix[breakpointId]}`
            data.breakpoint = newBreakpointId
        })
    })
}

const schemaVersion = '2.0'

const changeAllBreakpointsIdsInPage = (pageJson, uniqueIdGenerator) => {
    const breakpointsMap = _.get(pageJson.data, 'breakpoints_data')
    const pageBreakpointsDataNodes = _.pickBy(breakpointsMap, {type: 'BreakpointsData'})
    const pageBreakpointsRangeNodes = _.pickBy(breakpointsMap, {type: 'BreakpointRange'})

    const breakPointRangeNodesToFix = {}
    _.forEach(pageBreakpointsRangeNodes, breakpointRange => {
        const oldId = breakpointRange.id
        const generatedNewId = uniqueIdGenerator.getUniqueId('breakpoints', '-', {bucket: 'breakpoints'})
        breakpointRange.id = generatedNewId
        breakpointsMap[generatedNewId] = _.set(breakpointRange, 'metaData.schemaVersion', schemaVersion)
        delete breakpointsMap[oldId]
        breakPointRangeNodesToFix[oldId] = generatedNewId
    })

    _.forEach(pageBreakpointsDataNodes, breakpointDataItem => {
        const newValues = []
        breakpointDataItem.values.forEach(breakpointRangeId => {
            const oldId = stripHashIfExists(breakpointRangeId)
            const newId = `#${breakPointRangeNodesToFix[oldId]}`
            newValues.push(newId)
        })
        breakpointDataItem.values = newValues
    })
    fixRelationsBreakpointRef(pageJson, 'theme', breakPointRangeNodesToFix)
    fixRelationsBreakpointRef(pageJson, 'layout', breakPointRangeNodesToFix)
    fixImageXBreakpointRef(pageJson, breakPointRangeNodesToFix)
}

module.exports = {
    name: 'duplicatedBreakpointsIdsFixer',
    version: 1,
    exec: (pageJson, pageIdsArray, magicObject) => {
        if (magicObject.isViewerMode) {
            return
        }

        const {uniqueIdGenerator} = magicObject.dataFixerUtils
        const breakpointsMap = _.get(pageJson.data, 'breakpoints_data')

        if (!_.isEmpty(breakpointsMap)) {
            const pageBreakpointsRangeNodes = _.pickBy(breakpointsMap, {type: 'BreakpointRange'})

            const shouldFix = _.some(pageBreakpointsRangeNodes, ({metaData}) => parseFloat(metaData.schemaVersion) < parseFloat(schemaVersion))
            if (!shouldFix) {
                return
            }
            changeAllBreakpointsIdsInPage(pageJson, uniqueIdGenerator)
        }
    }
}
