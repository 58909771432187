'use strict'

const _ = require('lodash')

module.exports = {
    name: 'appPartTagsValueCustomizationFormatFixer',
    version: 1,
    exec(pageJson) {
        _(pageJson.data.document_data)
            .filter({type: 'AppPart'})
            .forEach(data => {
                _(data.appLogicCustomizations)
                    .filter({
                        fieldId: 'tagsValue',
                        forType: 'Post',
                        format: '',
                        key: 'value',
                        view: 'SinglePostMediaTop'
                    })
                    .forEach(customization => {
                        customization.format = '*'
                    })
            })
    }
}