'use strict'
const _ = require('lodash')
const constants = require('@wix/document-services-json-schemas/dist/constants.json')
const dataUtils = require('../helpers/dataUtils')
const {NAMESPACE_MAPPING, COMP_DATA_QUERY_KEYS} = constants

const {stripHashIfExists} = dataUtils

// Returns true if we're in a classic editor that has this data corruption
const checkIfCorruption = (structure, data) => {
    const {componentType} = structure
    const breakpointVariantsQuery = stripHashIfExists(structure[COMP_DATA_QUERY_KEYS.variants])
    const layoutQuery = stripHashIfExists(structure[COMP_DATA_QUERY_KEYS.layout])

    // Check if the component is a page and has a breakpointVariantsQuery
    if (componentType !== 'mobile.core.components.Page' || !breakpointVariantsQuery) {
        return false
    }

    const variants_data = data[NAMESPACE_MAPPING.variants]
    const layout_data = data[NAMESPACE_MAPPING.layout]

    if (!variants_data || !layout_data) {
        return false
    }

    const breakPointDataNodes = _.filter(variants_data, {type: 'BreakpointsData', id: breakpointVariantsQuery, componentId: structure.id})
    if (breakPointDataNodes.length !== 1 || !Array.isArray(breakPointDataNodes[0].values)) {
        return false
    }

    const breakpointRange = breakPointDataNodes[0].values.find(id => {
        const node = variants_data[stripHashIfExists(id)]
        return node && node.type === 'BreakpointRange' && node.min === 1001
    })

    if (!breakpointRange) {
        return false
    }

    // Check that the layoutQuery of the page component refers to a single layout value of type "RefArray"
    const refArrayNodes = _.filter(layout_data, {type: 'RefArray'})
    if (refArrayNodes.length !== 1 || refArrayNodes[0].id !== stripHashIfExists(layoutQuery)) {
        return false
    }

    return true
}

/**
 * Fixer that removes layout_data & variants_data from classic sites that are corrupted with responsive data
 * @param {*} structure
 * @param {*} data
 */
const fix = (structure, data) => {
    data[NAMESPACE_MAPPING.layout] = {}
    data[NAMESPACE_MAPPING.variants] = {}
    delete structure[COMP_DATA_QUERY_KEYS.layout]
    delete structure[COMP_DATA_QUERY_KEYS.variants]
}

module.exports = {
    name: 'removeResponsiveDataFromClassicEditorFixer',
    version: 1,
    exec(pageJson) {
        const {structure, data} = pageJson
        if (checkIfCorruption(structure, data)) {
            fix(structure, data)
        }
    }
}
