'use strict'

const _ = require('lodash')
const coreUtils = require('@wix/santa-core-utils')
const createUniqueIdGenerator = require('./createUniqueIdGenerator')

function fixPageData(plugins, {pageJson, urlFormatModel, isViewerMode, clientSpecMap, quickActionsMenuEnabled, experiments, pageIdsArray, pageId}) {
    const magicObject = {}
    magicObject.isSlash = _.matchesProperty('format', coreUtils.siteConstants.URL_FORMATS.SLASH)(urlFormatModel)
    magicObject.pageIdToResolvedUriSEO = _.get(urlFormatModel, 'pageIdToResolvedUriSEO', {})
    magicObject.clientSpecMap = clientSpecMap
    magicObject.quickActionsMenuEnabled = quickActionsMenuEnabled
    magicObject.isExperimentOpen = spec => _.includes(experiments, spec)
    magicObject.isViewerMode = isViewerMode
    magicObject.dataFixerUtils = {
        uniqueIdGenerator: createUniqueIdGenerator(pageJson, pageId)
    }

    const data = pageJson.data || {}
    data.document_data = data.document_data || {}
    data.theme_data = data.theme_data || {}
    data.component_properties = data.component_properties || {}
    data.mobile_hints = data.mobile_hints || {}
    pageJson.data = data
    _.forEach(plugins, plugin => {
        plugin.exec(pageJson, pageIdsArray, magicObject)
    })
    return pageJson
}

module.exports = pluginsManager => fixPageData.bind(null, pluginsManager.getPlugins())
