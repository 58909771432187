'use strict'

const _ = require('lodash')
const namespaceConfigs = require('@wix/document-services-json-schemas/dist/namespaceConfigs.json')

const publicNamespaceConfigs = _.pickBy(namespaceConfigs, {isPublicViewerData: true})
const privateNamespaceConfigs = _.omitBy(namespaceConfigs, {isPublicViewerData: true})

const publicDataMaps = _.map(publicNamespaceConfigs, 'mapName')
const publicDataQueries = _(publicNamespaceConfigs).pickBy('query').map('query').value()
const nonPublicDataQueries = _(privateNamespaceConfigs).pickBy('query').map('query').value()

module.exports = {
    publicDataMaps,
    publicDataQueries,
    nonPublicDataQueries
}
