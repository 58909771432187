'use strict'

const _ = require('lodash')
const {stripHashIfExists} = require('../helpers/dataUtils')

// CLNT-4002 - fix document media icon not showing due to storage url change

module.exports = {
    name: 'documentMediaFixer',
    version: 1,
    exec(pageJson) {
        if (pageJson.structure.type === 'Page') {
            const {components} = pageJson.structure
            const compProps = pageJson.data.document_data
            _.forEach(components, comp => {
                if (_.includes(comp.componentType, 'DocumentMedia')) {
                    const {dataQuery} = comp
                    const docCompData = compProps[stripHashIfExists(dataQuery)]
                    if (docCompData.uri.indexOf('media/') === 0) {
                        docCompData.uri = docCompData.uri.replace('media/', '')
                    }
                }
            })
        }
        return pageJson
    }
}
