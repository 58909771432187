'use strict'

const _ = require('lodash')

function hashCustomization(customization) {
    const rule = _.omit(customization, 'value')
    return JSON.stringify(rule)
}

module.exports = {
    name: 'appPartDuplicateCustomizationFixer',
    version: 1,
    exec(pageJson) {
        _(pageJson.data.document_data)
            .filter({type: 'AppPart'})
            .forEach(appPart => {
                const customizations = appPart.appLogicCustomizations
                let index = customizations.length
                const stateByHash = {}
                while (index) {
                    index -= 1
                    const hash = hashCustomization(customizations[index])
                    if (!stateByHash[hash]) {
                        stateByHash[hash] = true
                    } else {
                        customizations.splice(index, 1)
                    }
                }
            })
    }
}