'use strict'

const _ = require('lodash')

function removeZeroDisplayCount(comps) {
    _.forEach(comps, comp => {
        if (comp.type === 'BasicMenuItem' && comp.displayCount === 0) {
            comp.displayCount = undefined
        }
    })
}

/**
 * @exports utils/dataFixer/plugins/displayCountMenuDataItemFixer
 * @type {{exec: function}}
 */
module.exports = {
    name: 'displayCountMenuDataItemFixer',
    version: 1,
    exec(pageJson) {
        removeZeroDisplayCount(_.get(pageJson, ['data', 'document_data'], {}))

        _.forEach(_(pageJson).get('translations', []), translation => {
            removeZeroDisplayCount(_.get(translation, ['data', 'document_data'], {}))
        })
    }
}
