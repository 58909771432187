'use strict'

const _ = require('lodash')
const dataUtils = require('../helpers/dataUtils')

function createNewOriginalImageData(documentData, originalImageDataItemId, uniqueIdGenerator) {
    const newId = `${originalImageDataItemId}_${uniqueIdGenerator.getUniqueId()}`
    const newDataItem = _.cloneDeep(documentData[originalImageDataItemId])
    newDataItem.id = newId

    return newDataItem
}

function cloneDuplicateImageReferences(imagesDataArr, documentData, originalImageDataItemId, uniqueIdGenerator) {
    const imagesWithDuplicatedOriginalImageRef = _.tail(imagesDataArr)

    _.forEach(imagesWithDuplicatedOriginalImageRef, imageDataItem => {
        const newDataItem = createNewOriginalImageData(documentData, originalImageDataItemId, uniqueIdGenerator)
        documentData[newDataItem.id] = newDataItem
        imageDataItem.originalImageDataRef = `#${newDataItem.id}`
    })
}

module.exports = {
    name: 'originalImageDataRefFixer',
    version: 1,
    exec(pageJson, pageIdsArray, magicObject) {
        const {uniqueIdGenerator} = magicObject.dataFixerUtils
        const documentData = _.get(pageJson, ['data', 'document_data'])

        if (!documentData) {
            return
        }

        const imagesByOriginalDataRef = _(documentData)
            .pickBy({type: 'Image'})
            .pickBy('originalImageDataRef')
            .groupBy('originalImageDataRef')
            .value()

        _.forOwn(imagesByOriginalDataRef, (images, originalImageDataRef) => {
            const originalImageDataItemId = dataUtils.stripHashIfExists(originalImageDataRef)
            if (!documentData[originalImageDataItemId]) {
                _.forEach(images, image => {
                    delete image.originalImageDataRef
                })
                delete imagesByOriginalDataRef[originalImageDataRef]
            }
        })
        _(imagesByOriginalDataRef)
            .pickBy(imagesDataArr => imagesDataArr.length > 1)
            .forOwn((imagesDataArr, originalImageDataRef) => {
                cloneDuplicateImageReferences(imagesDataArr, documentData, dataUtils.stripHashIfExists(originalImageDataRef), uniqueIdGenerator)
            })
    }
}
