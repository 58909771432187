'use strict'

const _ = require('lodash')
const coreUtils = require('@wix/santa-core-utils')

module.exports = {
    name: 'blogSelectionSharerCustomizationsFixer',
    version: 1,
    exec(pageJson) {
        _(pageJson.data.document_data)
            .filter({
                type: 'AppPart',
                appPartName: coreUtils.blogAppPartNames.SINGLE_POST
            })
            .forEach(compData => {
                let customization = _.find(compData.appLogicCustomizations, {
                    forType: 'Post',
                    fieldId: 'vars',
                    key: 'selectionSharerExperimentOpen',
                    view: compData.viewName
                })

                if (!customization) {
                    customization = {
                        forType: 'Post',
                        fieldId: 'vars',
                        key: 'selectionSharerExperimentOpen',
                        view: compData.viewName,
                        type: 'AppPartCustomization',
                        format: ''
                    }
                    compData.appLogicCustomizations.push(customization)
                }
            })
    }
}