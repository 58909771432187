'use strict'
const _ = require('lodash')

const isFixed = structure => _.get(structure, 'layout.fixedPosition', false)
const correctFixedForPage = pageStructure => {
    if (isFixed(pageStructure)) {
        _.set(pageStructure, 'layout.fixedPosition', false)
    }
}

const findAllPathsToFixedComps = (structureNode, path = []) => {
    if (!structureNode) {
        return []
    }
    if (isFixed(structureNode)) {
        return [path]
    }
    const pathsToFixedFromChildren = findAllFixedCompsWithRootInChildren(structureNode, 'children', path)
    const pathsToFixedFromComponents = findAllFixedCompsWithRootInChildren(structureNode, 'components', path)
    return pathsToFixedFromChildren.concat(pathsToFixedFromComponents)
}

const findAllFixedCompsWithRootInChildren = (root, childrenProperty, path) => {
    const fixedCompsFromPath = []
    if (root && root[childrenProperty] && _.isArray(root[childrenProperty])) {
        const newPath = [].concat(path).concat(childrenProperty)
        for (let i = 0; i < root[childrenProperty].length; i++) {
            fixedCompsFromPath.unshift(...findAllPathsToFixedComps(root[childrenProperty][i], newPath.concat(i)))
        }
    }
    return fixedCompsFromPath
}

const POPUP_CONTAINER_TYPE = 'wysiwyg.viewer.components.PopupContainer'
const isPopupPage = pageJson => getPopupContainer(pageJson).componentType === POPUP_CONTAINER_TYPE
const getPopupContainer = popupPageJSON => _.get(popupPageJSON, 'structure.components.0', {})

const reArrangeFixedCompsAfterTheirRoots = (root, fixedCompsWithRoots) => {
    _.forEach(fixedCompsWithRoots, pathToFixedComp => {
        const fixedCompStructure = _.get(root, pathToFixedComp)
        removeFixedComponentFromSiblingsArray(root, pathToFixedComp)
        addFixedComponentToRootChildrenAfterPreviousParent(root, pathToFixedComp, fixedCompStructure)
    })
}

const addFixedComponentToRootChildrenAfterPreviousParent = (root, pathToFixedComp, compStructureToAdd) => {
    const rootChildren = root[_.head(pathToFixedComp)]
    const isFixedAlreadyDirectChild = rootChildren === _.get(root, pathToFixedComp.slice(0, pathToFixedComp.length - 1))
    const indexToAdd = pathToFixedComp[1] + (isFixedAlreadyDirectChild ? 0 : 1)
    rootChildren.splice(indexToAdd, 0, compStructureToAdd)
}

const removeFixedComponentFromSiblingsArray = (root, pathToFixedComp) => {
    const pathToSiblings = pathToFixedComp.slice(0, pathToFixedComp.length - 1)
    const siblingsArray = _.get(root, pathToSiblings)
    siblingsArray.splice(_.last(pathToFixedComp), 1)
}

module.exports = {
    name: 'fixedComponentParentFixer',
    version: 1,
    exec(pageJson) {
        const rootForReparent = isPopupPage(pageJson) ? getPopupContainer(pageJson) : pageJson.structure
        correctFixedForPage(rootForReparent)
        const pathsToFixedComps = findAllPathsToFixedComps(rootForReparent)
        reArrangeFixedCompsAfterTheirRoots(rootForReparent, pathsToFixedComps)
        return pageJson
    }
}
