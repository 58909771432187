'use strict'

const _ = require('lodash')
const coreUtils = require('@wix/santa-core-utils')

const STYLED_TEXT_TYPE = 'StyledText'

const createCollectUsedFontsFunction = ({unquote}) => styledTextDataItem => {
    const usedFonts = []

    coreUtils.htmlParser(styledTextDataItem.text, {
        start(tagName, attributes/*, isSingleton, tag*/) {
            const style = _.find(attributes, {name: 'style'})
            if (!style) {
                return
            }

            const valueUnquoted = unquote ? style.value.replace(/&quot;/g, '') : style.value

            const fontFamily = /font-family:([^;]+);?/ig.exec(valueUnquoted)
            if (fontFamily) {
                usedFonts.push(fontFamily[1].trim())
            }
        }
    })

    _.set(styledTextDataItem, 'usedFonts', usedFonts)
}

const createCollectUsedFontsFromDataFunction = config => json => {
    const collectUsedFonts = createCollectUsedFontsFunction(config)
    _(json.data.document_data)
        .filter(({type, usedFonts}) => !usedFonts && type === STYLED_TEXT_TYPE)
        .forEach(collectUsedFonts)
}

/**
 * @exports utils/dataFixer/plugins/richTextSkinDataFixer
 * @type {{exec: function}}
 */
module.exports = {
    name: 'richTextUsedFontsDataFixer',
    version: 1,
    exec(pageJson, pageIdsArray, magicObject) {
        if (!magicObject.isExperimentOpen('sv_usedFontsDataFixer')) {
            return
        }

        const unquote = magicObject.isExperimentOpen('sv_unquoteUsedFontsInDataFixer')

        const collectUsedFontsFromData = createCollectUsedFontsFromDataFunction({unquote})

        try {
            collectUsedFontsFromData(pageJson)
            _(pageJson.translations).forEach(collectUsedFontsFromData)
        } catch (e) {
            // it's not my fault, there's nothing I can do :(
        }

        return pageJson
    }
}
