'use strict'

/**
 * @exports utils/dataFixer/plugins/fromDocumentToThemeData
 * @type {{exec: function}}
 */
module.exports = {
    name: 'fromDocumentToThemeData',
    version: 1,
    exec(pageJson) {
        const {data} = pageJson
        if (data.document_data.THEME_DATA) {
            if (!data.theme_data.THEME_DATA) {
                data.theme_data.THEME_DATA = data.document_data.THEME_DATA
            }
            delete data.document_data.THEME_DATA
        }
    }
}
