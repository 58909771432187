'use strict'

const _ = require('lodash')

/**
 * @exports utils/dataFixer/plugins/connectionsDataFixer
 * @type {{exec: function}}
 */
module.exports = {
    name: 'connectionsDataFixer',
    version: 1,
    exec(pageJson) {
        const pageData = _.get(pageJson, 'data')
        pageData.connections_data = pageData.connections_data || {}
    }
}
