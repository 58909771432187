'use strict'

const _ = require('lodash')

function fixSendEmailField(dataItem) {
    if (!dataItem.metaData || dataItem.metaData.schemaVersion !== '2.0') {
        dataItem.sendMail = true
    }
}

function fixData(data) {
    _.forEach(data, dataItem => {
        if (dataItem.type === 'LoginToWixLink' || dataItem.type === 'HomePageLogin') {
            fixSendEmailField(dataItem)
        }
    })
}

/**
 * @exports utils/dataFixer/plugins/backgroundsDataFixer
 * @type {{exec: function}}
 */
module.exports = {
    name: 'homePageLoginDataFixer',
    version: 1,
    exec(pageJson) {
        const data = pageJson.data.document_data
        if (!_.isEmpty(data)) {
            fixData(data)
        }
    }
}
