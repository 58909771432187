'use strict'
const {createHelperAndMigratePage} = require('../helpers/CompsMigrationHelper')

/**
 * @param {{componentType: string, dataQuery?: *}} arg
 * @returns {function(*, *, *, *): void}
 */
const migrateComponentType = ({componentType, dataQuery}) => (pageJson, cache, mobileView, comp) => {
    comp.componentType = componentType
    if (dataQuery) {
        comp.dataQuery = dataQuery
    }
}

const MIGRATION_MAP = {
    'wysiwyg.viewer.components.ClipArt': migrateComponentType({
        componentType: 'wysiwyg.viewer.components.WPhoto'
    }),
    'wysiwyg.viewer.components.HorizontalMenu': migrateComponentType({
        componentType: 'wysiwyg.viewer.components.menus.DropDownMenu',
        dataQuery: '#CUSTOM_MAIN_MENU'
    })
}

module.exports = {
    name: 'componentTypeMigrator',
    version: 1,
    exec: createHelperAndMigratePage(MIGRATION_MAP)
}
