'use strict'
const _ = require('lodash')
const {createHelperAndMigratePage} = require('../helpers/CompsMigrationHelper')
const themeDataUtils = require('../helpers/themeDataUtils')

function getPlaceholderValue(compProp, compData) {
    return _.get(compProp, ['placeholder', 'value']) || _.get(compData, ['placeholder', 'value'])
}

function fixPlaceholderStyles(pageJson, cache, mobileView, comp) {
    const fixPlaceholderStyle = styleDefinition => {
        const styleProperties = _.get(styleDefinition, ['style', 'properties'])
        if (!styleProperties) {
            return
        }
        const compProp = this.getComponentProperties(pageJson, comp)
        const compData = this.getComponentData(pageJson, comp)
        const isLegacyPlaceholderSelected = compData.value === getPlaceholderValue(compProp, compData)

        if (styleProperties.txt_placeholder) {
            return
        }

        if (isLegacyPlaceholderSelected) {
            _.assign(styleProperties, {
                txt_placeholder: styleProperties.txt2
            })
        } else {
            _.assign(styleProperties, {
                txt_placeholder: styleProperties.txt
            })
        }
    }

    themeDataUtils.forEachComponentStyle(pageJson.data.theme_data, comp.styleId, fixPlaceholderStyle)
}

const MIGRATION_MAP = {
    'wysiwyg.viewer.components.inputs.ComboBoxInput': fixPlaceholderStyles
}

/**
 * @exports utils/dataFixer/plugins/comboBoxPlaceholderStyleFixer
 * @type {{exec: function}}
 */
module.exports = {
    name: 'comboBoxPlaceholderStyleFixer',
    version: 1,
    exec: createHelperAndMigratePage(MIGRATION_MAP)
}
