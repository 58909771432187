'use strict'

const {createHelperAndMigratePage} = require('../helpers/CompsMigrationHelper')

const createSospStyleItem = uniqueIdGenerator => ({
    type: 'ComponentStyle',
    styleType: 'custom',
    id: uniqueIdGenerator.getUniqueId('style', '-'),
    skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
    componentClassName: 'wysiwyg.viewer.components.SiteRegionContainer',
    metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false
    },
    style: {
        groups: {},
        properties: {
            'alpha-bg': '1',
            'alpha-brd': '1',
            bg: 'color_11',
            'boxShadowToggleOn-shd': 'false',
            brd: 'color_15',
            brw: '0',
            rd: '0px',
            shd: '0px 0px 0px 0px rgba(0,0,0,0)'
        },
        propertiesSource: {
            'alpha-bg': 'value',
            'alpha-brd': 'value',
            bg: 'theme',
            'boxShadowToggleOn-shd': 'value',
            brd: 'theme',
            brw: 'value',
            rd: 'value',
            shd: 'value'
        }
    }
})

function handleSospWithWrongSkin(pageJson, cache, mobileView, comp, uniqueIdGenerator) {
    if (comp.id === 'SOSP_CONTAINER_CUSTOM_ID' && comp.styleId === 'sirc1') {
        comp.skin = undefined
        const sospStyleItem = createSospStyleItem(uniqueIdGenerator)
        comp.styleId = sospStyleItem.id
        this.setComponentStyle(pageJson, comp, sospStyleItem, cache)
    }
}

const MIGRATION_MAP = {
    'wysiwyg.viewer.components.SiteRegionContainer': handleSospWithWrongSkin
}

module.exports = {
    name: 'sospSkinDataFixer',
    version: 1,
    exec: createHelperAndMigratePage(MIGRATION_MAP)
}
