'use strict'

const _ = require('lodash')

const skinToLayoutMap = {
    'wysiwyg.viewer.skins.line.FadeLine': {width: 90},
    'wysiwyg.viewer.skins.line.FadeNotchBottomLine': {width: 60},
    'wysiwyg.viewer.skins.line.FadeNotchTopLine': {width: 60},
    'wysiwyg.viewer.skins.line.ShadowBottomLine': {width: 200},
    'wysiwyg.viewer.skins.line.ShadowTopLine': {width: 200}
}

function fixFiveGridLineLayout(comps) {
    _.forEach(comps, comp => {
        if (_.has(skinToLayoutMap, comp.skin)) {
            const layout = skinToLayoutMap[comp.skin]
            comp.layout = _.mapValues(comp.layout, (val, key) => {
                if (layout[key]) {
                    return Math.max(val, layout[key])
                }
                return val
            })
        }

        if (comp.components) {
            fixFiveGridLineLayout(comp.components)
        }
    })
}

/**
 * @exports utils/dataFixer/plugins/fiveGridLineLayoutFixer
 * @type {{exec: function}}
 */
module.exports = {
    name: 'fiveGridLineLayoutFixer',
    version: 1,
    exec(pageJson) {
        const structureData = pageJson.structure
        if (!structureData) {
            return
        }
        if (structureData.components) {
            fixFiveGridLineLayout(structureData.components)
        }
        if (structureData.children) {
            fixFiveGridLineLayout(structureData.children)
        }
        if (structureData.mobileComponents) {
            fixFiveGridLineLayout(structureData.mobileComponents)
        }
    }
}
