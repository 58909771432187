'use strict'

const coreUtils = require('@wix/santa-core-utils')
const pageJsonFileNamePattern = /^[0-9a-f]{6}_[0-9a-f]{32}_\d+$/

function getPageId(pageJson) {
    return pageJson.structure.type === 'Document' ? 'masterPage' : pageJson.structure.id
}

function createUniqueIdGenerator(pageJson, pageJsonFileName) {
    if (pageJsonFileNamePattern.test(pageJsonFileName)) {
        const pageId = getPageId(pageJson)
        const [, , pageRevision] = pageJsonFileName.split('_')
        const seed = `${pageId}-${Number(pageRevision).toString(36)}`
        return coreUtils.guidUtils.getUniqueIdGenerator(seed)
    }

    return {getUniqueId: coreUtils.guidUtils.getUniqueId}
}

module.exports = createUniqueIdGenerator
