'use strict'
const {createHelperAndMigratePage} = require('../helpers/CompsMigrationHelper')

const MIGRATION_MAP = {
    'wysiwyg.common.components.verticalmenu.viewer.VerticalMenu': migrateCompToSupportMultipleMenus,
    'wysiwyg.viewer.components.menus.DropDownMenu': migrateCompToSupportMultipleMenus,
    'wysiwyg.viewer.components.mobile.TinyMenu': migrateTinyMenuCompToSupportMultipleMenus
}

function migrateCompToSupportMultipleMenus(pageJson, cache, mobileView, comp) {
    const compData = this.getComponentData(pageJson, comp)
    if (!compData || compData.type !== 'CustomMenuDataRef') {
        delete comp.dataQuery
        this.setComponentData(pageJson, comp, {
            menuRef: '#CUSTOM_MAIN_MENU',
            type: 'CustomMenuDataRef'
        }, cache)
    }
}

function migrateTinyMenuCompToSupportMultipleMenus(pageJson, cache, mobileView, comp) {
    const compData = this.getComponentData(pageJson, comp)
    if (!compData || compData.type !== 'TinyMenu') {
        delete comp.dataQuery
        this.setComponentData(pageJson, comp, {
            menuRef: '#CUSTOM_MAIN_MENU',
            type: 'TinyMenu'
        }, cache)
    }
}


module.exports = {
    name: 'multipleMenusFixer',
    version: 1,
    exec: createHelperAndMigratePage(MIGRATION_MAP)
}
