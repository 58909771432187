'use strict'
const imageClientApi = require('image-client-api/dist/imageClientApi')
const {fittingTypes} = imageClientApi
const {alignTypes} = imageClientApi

const tilingMap = {
    'repeat-x': fittingTypes.TILE_HORIZONTAL,
    'repeat no_repeat': fittingTypes.TILE_HORIZONTAL,
    'repeat no-repeat': fittingTypes.TILE_HORIZONTAL,
    'repeat-y': fittingTypes.TILE_VERTICAL,
    'no_repeat repeat': fittingTypes.TILE_VERTICAL,
    'no-repeat repeat': fittingTypes.TILE_VERTICAL,
    repeat: fittingTypes.TILE,
    'repeat repeat': fittingTypes.TILE,
    // Default (keep empty)
    '': ''
}

Object.freeze(tilingMap)

const fittingTypeMap = {
    'auto'(tile) {
        return tile ? tile : fittingTypes.LEGACY_ORIGINAL_SIZE
    },
    'cover'() {
        return fittingTypes.SCALE_TO_FILL
    },
    'contain'(tile) {
        return tile ? fittingTypes.FIT_AND_TILE : fittingTypes.SCALE_TO_FIT
    },
    // Default
    ''() {
        return fittingTypes.SCALE_TO_FILL
    }
}

Object.freeze(fittingTypeMap)

const alignTypeMap = {
    'center center': alignTypes.CENTER,
    center: alignTypes.CENTER,
    '50% 50%': alignTypes.CENTER,

    'top center': alignTypes.TOP,
    'center top': alignTypes.TOP,
    '50% 0%': alignTypes.TOP,

    'bottom center': alignTypes.BOTTOM,
    'center bottom': alignTypes.BOTTOM,
    '50% 100%': alignTypes.BOTTOM,

    'center right': alignTypes.RIGHT,
    'right center': alignTypes.RIGHT,
    '100% 50%': alignTypes.RIGHT,

    'center left': alignTypes.LEFT,
    'left center': alignTypes.LEFT,
    '0% 50%': alignTypes.LEFT,

    'left top': alignTypes.TOP_LEFT,
    'top left': alignTypes.TOP_LEFT,
    '0% 0%': alignTypes.TOP_LEFT,

    'right top': alignTypes.TOP_RIGHT,
    'top right': alignTypes.TOP_RIGHT,
    '100% 0%': alignTypes.TOP_RIGHT,

    'left bottom': alignTypes.BOTTOM_LEFT,
    'bottom left': alignTypes.BOTTOM_LEFT,
    '0% 100%': alignTypes.BOTTOM_LEFT,

    'right bottom': alignTypes.BOTTOM_RIGHT,
    'bottom right': alignTypes.BOTTOM_RIGHT,
    '100% 100%': alignTypes.BOTTOM_RIGHT,

    // Default
    '': alignTypes.CENTER
}

Object.freeze(alignTypeMap)

const fittingToRepeatMap = (function () {
    const map = {}
    map[fittingTypes.LEGACY_STRIP_TILE_HORIZONTAL] = 'repeat-x'
    map[fittingTypes.TILE_HORIZONTAL] = 'repeat-x'
    map[fittingTypes.LEGACY_STRIP_TILE_VERTICAL] = 'repeat-y'
    map[fittingTypes.TILE_VERTICAL] = 'repeat-y'
    map[fittingTypes.LEGACY_STRIP_TILE] = 'repeat'
    map[fittingTypes.TILE] = 'repeat'
    map[fittingTypes.FIT_AND_TILE] = 'repeat'

    // Default
    map[''] = 'no-repeat'
    return map
}())

Object.freeze(fittingToRepeatMap)

const fittingToBgMap = (function () {
    const map = {}
    map[fittingTypes.LEGACY_ORIGINAL_SIZE] = 'auto'
    map[fittingTypes.LEGACY_STRIP_ORIGINAL_SIZE] = 'auto'
    map[fittingTypes.SCALE_TO_FILL] = 'cover'
    map[fittingTypes.LEGACY_STRIP_SCALE_TO_FILL] = 'cover'
    map[fittingTypes.SCALE_TO_FIT] = 'contain'
    map[fittingTypes.LEGACY_STRIP_SCALE_TO_FIT] = 'contain'
    map[fittingTypes.FIT_AND_TILE] = 'contain'
    map[fittingTypes.LEGACY_STRIP_FIT_AND_TILE] = 'contain'

    // Default
    map[''] = 'auto'
    return map
}())

Object.freeze(fittingToBgMap)

const alignToPositionMap = (function () {
    const map = {}
    map[alignTypes.TOP] = 'center top'
    map[alignTypes.CENTER] = 'center'
    map[alignTypes.BOTTOM] = 'center bottom'

    map[alignTypes.TOP_LEFT] = 'left top'
    map[alignTypes.LEFT] = 'left center'
    map[alignTypes.BOTTOM_LEFT] = 'left bottom'

    map[alignTypes.TOP_RIGHT] = 'right top'
    map[alignTypes.RIGHT] = 'right center'
    map[alignTypes.BOTTOM_RIGHT] = 'right bottom'

    // Default
    map[''] = 'center'
    return map
}())

Object.freeze(alignToPositionMap)

/**
 * sometime css values are saved with spaces. here we are cleaning and making sure all values are lower case.
 * @param {string} value
 * @returns {string}
 */
function cleanupDataStrings(value) {
    return (value || '').toLowerCase().trim()
}

function cssToFittingType(cssValues) {
    const bgRepeat = cleanupDataStrings(cssValues.bgRepeat)
    const bgSize = cleanupDataStrings(cssValues.bgSize)

    const tile = tilingMap[bgRepeat]
    return fittingTypeMap[bgSize] && fittingTypeMap[bgSize](tile)
}

function cssToAlignType(cssValues) {
    cssValues = cleanupDataStrings(cssValues)
    return alignTypeMap[cssValues]
}

function fittingTypeToBgRepeat(fittingType) {
    return fittingToRepeatMap[fittingType] || fittingToRepeatMap['']
}

function fittingTypeToBgSize(fittingType) {
    return fittingToBgMap[fittingType] || fittingToBgMap['']
}

function alignTypeToBgPosition(alignType) {
    return alignToPositionMap[alignType] || alignToPositionMap['']
}

function migrateToLegacyFittings(fittingType) {
    if (fittingType === fittingTypes.TILE) {
        return fittingTypes.LEGACY_STRIP_TILE
    } else if (fittingType === fittingTypes.TILE_HORIZONTAL) {
        return fittingTypes.LEGACY_STRIP_TILE_HORIZONTAL
    } else if (fittingType === fittingTypes.TILE_VERTICAL) {
        return fittingTypes.LEGACY_STRIP_TILE_VERTICAL
    } else if (fittingType === fittingTypes.SCALE_TO_FILL) {
        return fittingTypes.LEGACY_STRIP_SCALE_TO_FILL
    } else if (fittingType === fittingTypes.SCALE_TO_FIT) {
        return fittingTypes.LEGACY_STRIP_SCALE_TO_FIT
    } else if (fittingType === fittingTypes.FIT_AND_TILE) {
        return fittingTypes.LEGACY_STRIP_FIT_AND_TILE
    } else if (fittingType === fittingTypes.LEGACY_ORIGINAL_SIZE) {
        return fittingTypes.LEGACY_STRIP_ORIGINAL_SIZE
    }

    return fittingType
}


module.exports = {
    cssToFittingType,
    cssToAlignType,
    fittingTypeToBgRepeat,
    fittingTypeToBgSize,
    alignTypeToBgPosition,
    migrateToLegacyFittings
}
