'use strict'

const _ = require('lodash')

const galleryPropertyTypes = ['GalleryExpandProperties', 'MatrixGalleryProperties', 'PaginatedGridGalleryProperties', 'SliderGalleryProperties', 'SlideShowGalleryProperties']

function fixGalleryFn(gallery) {
    if (_.isUndefined(gallery.galleryImageOnClickAction) || gallery.galleryImageOnClickAction === 'unset') {
        if (gallery.expandEnabled === false) {
            gallery.galleryImageOnClickAction = 'disabled'
        } else {
            gallery.galleryImageOnClickAction = 'zoomMode'
        }
    }
    return gallery
}

function fixDataNodesForTypes(dataMap, types, mapper) {
    return _(dataMap)
        .pickBy(dataNode => _.includes(types, dataNode.type))
        .mapValues(mapper)
        .value()
}

/**
 * @exports utils/dataFixer/plugins/galleryFixer
 * @type {{exec: function}}
 */
module.exports = {
    name: 'galleryFixer',
    version: 1,
    exec(pageJson) {
        fixDataNodesForTypes(pageJson.data.component_properties, galleryPropertyTypes, fixGalleryFn)
    }
}
