'use strict'
const _ = require('lodash')
const dataUtils = require('../helpers/dataUtils')

function isJsonForPage(pageJson) {
    return _.get(pageJson, 'structure.type') === 'Page'
}

function removeMenuData(document_data) {
    const menuDataItems = _.pickBy(document_data, {type: 'CustomMenu'})
    const basicMenuItems = _.pickBy(document_data, {type: 'BasicMenuItem'})
    _.forOwn(menuDataItems, (v, id) => {
        delete document_data[id]
    })
    _.forOwn(basicMenuItems, (v, id) => {
        delete document_data[id]
        if (v.link) {
            delete document_data[dataUtils.stripHashIfExists(v.link)]
        }
        if (v.iconRef) {
            delete document_data[dataUtils.stripHashIfExists(v.iconRef)]
        }
    })
}

function menuDataOnPageFixer(pageJson, pageIdsArray, magicObject) {
    if (magicObject.isExperimentOpen('bv_removeMenuDataFromPageJson') && magicObject.isViewerMode && isJsonForPage(pageJson)) {
        const {document_data} = pageJson.data
        removeMenuData(document_data)
        _.forOwn(pageJson.translations, translationLanguage => removeMenuData(translationLanguage.data.document_data))
    }
}

module.exports = {
    name: 'menuDataOnPageFixer',
    version: 1,
    exec: menuDataOnPageFixer
}
