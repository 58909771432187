'use strict'


const _ = require('lodash')


function isJsonForPage(pageJson) {
    return _.get(pageJson, 'structure.type') === 'Page'
}

function removePageDataItems(pageJson) {
    _(pageJson.data.document_data)
        .filter({type: 'Page'})
        .map('id')
        .forEach(pageId => {
            delete pageJson.data.document_data[pageId]
        })
}

function updateLinksMasterPageId(pageJson) {
    _.forEach(pageJson.data.document_data, dataItem => { //eslint-disable-line lodash/prefer-filter
        if (dataItem.pageId === '#SITE_STRUCTURE') {
            dataItem.pageId = '#masterPage'
        }
    })
}

function pageDataFixer(pageJson) {
    if (isJsonForPage(pageJson)) {
        removePageDataItems(pageJson)
    }
    updateLinksMasterPageId(pageJson)
}

module.exports = {
    name: 'pageDataFixer',
    version: 1,
    exec: pageDataFixer
}